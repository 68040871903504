import Header from "../../src/header/Header";
import Footer from "../../src/footer/Footer";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function MainLayout({children}: { children: React.ReactNode; }) {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.classList.remove('lang-en', 'lang-am');
    document.body.classList.add('lang-' + i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Header/>
        {children}
      <Footer/>
    </>
  );
}
