import styles from "./footer.module.scss";
import { Container, Nav } from 'react-bootstrap';
import { NavLink } from '../../libs/navlink/NavLink';
import { useTranslation } from 'react-i18next';

export default function Footer({ isTempHome = false }) {
    const { t, i18n } = useTranslation();

    const footerItemsLeft = [
        // {name: "About Us", href: "/about-us"},
        {name: t('headerAbout'), href: "/about"},
        {name: t('headerContact'), href: "/contact"},
    ];

    const footerItemsCenter = [
        {name: t('headerPainting'), href: "/painting"},
        {name: t('headerFresca'), href: "/frescas"},
        {name: t('headerGraphic'), href: "/graphic"},
    ]

    return (
        <section className={styles.footer}>
            <Container className={'d-flex justify-content-between footer-container'}>
                {/*<div>*/}
                {/*    <Nav className={"align-items-center justify-content-center"}>*/}
                {/*        {*/}
                {/*            footerItemsLeft.map(menuItem => (*/}
                {/*                <NavLink key={menuItem.href} href={menuItem.href}>{menuItem.name}</NavLink>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </Nav>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Nav className={"align-items-center justify-content-center"}>*/}
                {/*        {*/}
                {/*            footerItemsCenter.map(menuItem => (*/}
                {/*                <NavLink key={menuItem.href} href={menuItem.href}>{menuItem.name}</NavLink>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </Nav>*/}
                {/*</div>*/}
                <div>
                    <span>&copy; Տ․Թոքմաջյանի ընտանիք</span>
                </div>
            </Container>
        </section>
    );
}
