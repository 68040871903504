/** @type {import('next').NextConfig} */
const nextConfig = {
    trailingSlash: true,
    reactStrictMode: true,
    productionBrowserSourceMaps: true,
    images: {
        unoptimized: true,
    },
    async rewrites() {
        return [
            {
                source: "/api/:path*",
                basePath: false,
                destination: "http://localhost:3000/api/:path*", // Proxy to Backend rest
            }
        ];
    }
};

module.exports = nextConfig;
