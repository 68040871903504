import {Container, Nav} from "react-bootstrap";
import styles from "./header.module.scss";
import { NavLink } from "../../libs/navlink/NavLink";
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

export default function Header() {
    const { t, i18n } = useTranslation();
    const router = useRouter();

    const headerMenu = [
        {name: t('headerHome'), href: "/"},
        {name: t('headerPainting'), href: "/painting"},
        {name: t('headerFresca'), href: "/frescas"},
        {name: t('headerGraphic'), href: "/graphic"},
        {name: t('headerAbout'), href: "/about"},
        {name: t('headerContact'), href: "/contact"},
    ];

    return (
        <section className={styles.header}>
            <Container fluid={"lg"}
                       className={`flex flex-row align-items-center justify-content-end flex-xs-column gap-3`}>
                <span className={'language-switcher'}>
                    <span style={i18n.language === 'en' ? {color: '#fff'} : {}} onClick={() => i18n.changeLanguage('en')}>ENG</span>
                    <span style={i18n.language === 'am' ? {color: '#fff'} : {}} onClick={() => i18n.changeLanguage('am')}>ՀԱՅ</span>
                </span>
            </Container>
            <Container fluid={"lg"}
                       className={`flex flex-row align-items-center justify-content-start flex-xs-column gap-3`}>
                <span className="logo">{t('headerName')}</span>
            </Container>
            <div style={{height: 1, backgroundColor: "#3A4E5C", width: "100%"}}></div>
            {/*<Container fluid={"lg"}*/}
            {/*           className={`flex flex-row align-items-center justify-content-end flex-xs-column gap-3`}>*/}
            {/*    <Nav className={"align-items-center justify-content-center"}>*/}
            {/*        {*/}
            {/*            headerMenu.map(menuItem => (*/}
            {/*                <NavLink key={menuItem.href} className={router.pathname === menuItem.href ? 'active' : ''} href={menuItem.href}>{menuItem.name}</NavLink>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </Nav>*/}
            {/*</Container>*/}
        </section>
    );
}
