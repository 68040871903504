import "./globals.scss";
import React, {useEffect, useState} from "react";
import Head from "next/head";
import {useRouter} from "next/router";
import {Loader} from "../libs/loader/Loader";
import MainLayout from "../libs/layout/MainLayout";
import config from '../next.config';

import "../src/i18n";

function MyApp({Component, pageProps}: any) {
    const router = useRouter();
    const [loading, setLoading] = useState<boolean>(false);

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout || ((page: JSX.Element) => (
        <MainLayout>{page}</MainLayout>
    ));

    useEffect(() => {
        const handleStart = (url: string) => {
            url.split("?")[0] !== location.pathname ? setLoading(true) : setLoading(false);
        };
        const handleComplete = (url: string | Error) => {
            const baseUrl = typeof url === 'string' && url?.replace?.(config.basePath as string || '', '') || '';
            setLoading(false);
        };

        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleComplete);
        router.events.on("routeChangeError", handleComplete);
    }, [router]);

    useEffect(() => {
        router.isReady && router.push(location.pathname);
    }, [router.isReady]);

    return (
        <main>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Head>
            {loading ? (
                <div className={"page-loader"}>
                    <Loader/>
                </div>
            ) : null}
            {
                getLayout(<Component {...pageProps} />)
            }
        </main>
    );
}

export default MyApp;
