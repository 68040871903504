import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    headerName: 'Tigran Tokmajyan',
                    headerHome: 'home',
                    headerPainting: 'painting',
                    headerFresca: 'fresca',
                    headerGraphic: 'graphics',
                    headerAbout: 'about painter',
                    headerContact: 'contact',
                    painting_1_description: 'Test',
                    painting_2_description: 'Test',
                    painting_3_description: 'Test',
                    painting_4_description: 'Test',
                    painting_5_description: 'Test',
                    painting_6_description: 'Test',
                    painting_7_description: 'Test',
                    fresca_1_description: 'Test',
                    linocut_description: 'Test',
                    illustrations_description: 'Test',
                    illustrations_2_description: 'Test',
                    illustrations_3_description: 'Test',
                    painting: 'painting',
                    frescas: 'fresca',
                    fresca_sketches: 'fresca sketches',
                    figure_history: 'figure_history',
                    figure_household: 'figure_household',
                    linocut: 'linocut',
                    illustrations: 'illustrations',
                    other: 'other',
                    temp_home_description: `
                    <p>Tigran Tokmajyan’s (1923-2004) landscapes, pieces in historical and genre art, and large multi-figured murals have a crucial place in 20th century Armenian art.</p>
                    <p>Armenians and the aspects of their identity take center stage in the artist's vibrant works. The significant and fatal events of Armenian history, the individuals who left significant cultural legacy, and the nature - the very setting where Armenians live and create - are the fundamental elements to understand Armenian identity in Tokmajyan’s perspective.</p>
                    <p>The artworks of Tigran Tokmajyan are held in the National Gallery of Armenia, in the Mother See of Holy Etchmiadzin Cathedral, in the repository of the Union of Artists of Armenia, as well as various public and private collections in Armenia, USA, Belgium, United Kingdom, Syria etc...</p>
                    <p>More coming soon...</p>
                    `
                }
            },
            am: {
                translations: {
                    headerName: 'Տիգրան Թոքմաջյան',
                    headerHome: 'սկիզբ',
                    headerPainting: 'գեղանկար',
                    headerFresca: 'որմնանկար',
                    headerGraphic: 'գրաֆիկա',
                    headerAbout: 'նկարչի մասին',
                    headerContact: 'կապ',
                    painting_1_description: 'Տիգրան Թոքմաջյանի հյութեղ կոլորիտով վրձնած բազմաթիվ բնանկարները ներկայացնում են Հայաստանի գունագեղ, հիասքանչ բնությունը՝ իր սարեր-ձորերով, հինավուրց վանքերով, գյուղական պատկերներով։ Այդ բնաշխարհը նկարչին նախ և առաջ հետաքրքրում է որպես միջավայր, ուր դարեր ի վեր ապրում և արարում են հայերը։ Նա իր ստեղծագործական կյանքի ամբողջ ընթացքում հաճախ ճամփորդել է Հայաստանի տարբեր վայրեր՝ ձգտելով պատկերել հարազատ երկրի հնարավորինս ամբողջական կերպարը։',
                    painting_2_description: 'Պատմական թեմաներով ֆիգուրատիվ կոմպոզիցիաները կենտրոնական տեղ են զբաղեցնում Տիգրան Թոքմաջյանի արվեստում։ Հիմնվելոով «մշակույթ-պետականություն-կրոն» եռամիասնության գաղափարախոսության վրա՝ Թոքմաջյանը պատկերում է հայ ժողովրդի պատմության և մշակույթի անկյունաքարային իրադարձությունները՝ կարևորելով նշանակալից դեր ունեցած գործիչներին։ Նա անդրադառնում է նաև հայկական առասպելաբանությանը և կտակարանին՝ այն ամենին, ինչը որոշիչ դեր է խաղացել հայերի ազգային ինքնագիտակցության կայացման համար։',
                    painting_3_description: 'Թոքմաջյանի պատմական թեմաներով գործերից շատերի ակունքները գալիս են հայ պատմիչների՝ հատկապես Մովսես Խորենացու, Եղիշեի գրվածքներից և ավետարանից։',
                    painting_4_description: 'Հայաստանի կերպարը ամբողջական չէր լինի առանց այստեղ ապրող մարդկանց։ Տիգրան Թոքմաջյանի կենցաղային թեմաներով աշխատանքների հերոսները բնության հետ ներդաշնակ ապրող, աշխատող մարդիկ են՝ հիմնականում գյուղացիներ, իրենց որախություններով ու մտահոգություններով։ Թոքմաջյանի՝ անմիջականությամբ աչքի ընկնող, այս կտավները լի են կենսահաստատ լավատեսությամբ։',
                    painting_5_description: 'Տիգրան Թոքմաջյանի գեղանկարի հիմնական ուղղությունները բնանկարը, պատմական և կենցաղային ժանրի կտավներն են, սակայն ժամանակ առ ժամանակ նա անդրադարձել է նաև այլ ժանրերի։',
                    painting_6_description: 'Ծաղիկներով նատյուրմորտները նկարչի համար բնության զարթոնքը ներկայացնելու միջոց է։ Գույների այս խրախճանքը, կարծես էլ ավելի ընդգծելու համար, հաճախ նա որպես ֆոն օգտագոծում է հայկական կարպետը։',
                    painting_7_description: 'Ուշադրության են արժանի նաև տարբեր տարիքներում արված ինքնադիմանկարներն ու սակավաթիվ դիմանկարները։',
                    fresca_1_description: `1983-2002թթ Տիգրան Թոքմաջյանը իրականացնում է մի շարք մեծածավալ որմնանկարներ Թալինի շրջանի Դավթաշեն գյուղի մշակույթի տանը, Երևանի երկու դպրոցներում, Հալեպում, Դամասկոսում  և  մի քանի առանձնատներում։
                                            Որմնանկարները Տիգրան Թոքմաջյանի արվեստի շատ կարևոր ասպեկտ է։ Այստեղ լավագույնս է դրսևորվում նրա մոնումենտալ մտածողությունը և մեծածավալ բազմաֆիգուր պատկերներ «բեմադրելու» մեծ վարպետությունը։ Զարգացնելով գեղանկարում իր արծարծած պատմա-հայրենասիրական թեման, նա որմնանկարնկարոներում այն ներկայացնում է առավել ամբողջական և ընդգրկուն։
                                            Նկարիչը որմնանկարների վրա աշխատում էր մեծ խանդավառությամբ, քանի որ, կերպարվեսի այս տեսակը մեծ հնարավորություն էր տալիս ծառայելու իր գլխավոր նպատակին՝ այն է․ նպաստել ազգային-քաղաքական գիտակցությունը վերհանմանը և ամրապնդմանը։`,
                    linocut_description: 'Գեղանկարչությանը զուգահեռ Տիգրան Թոքմաջյանը աշխատում է նաև գրաֆիկայի ժանրում՝ կատարելով բազմաթիվ լինոփորագրություններ։ Տիրապետելով այս ժանրի յուրահատուկ «լեզվին» հասնում է մեծ արտահայչականության։ Վաղ շրջանի թեմաները բազմազան են, ավելի ուշ շրջանում Թոքմաջյանը կենտրոնանում է հայ առասպեների շարքի վրա։',
                    illustrations_description: 'Ստեղծագործական ճանապարհի տարբեր հատվածներում Տիգրան Թոքմաջյանը կատարել է գրքերի նկարազադումներ և գրաֆիկական էջեր։',
                    illustrations_2_description: 'Նոր կտակարանի նկարազարդումները Թոքմաջյանի նկարչության օրգանական շարունակությունն են։ Այստեղ որոշ թեմաների ավանդական կոմպոզիցիոն լուծումների կողքին կան նաև ինքնատիպ մեկնաբանությանբ արված դրվագներ։',
                    illustrations_3_description: 'Հայկական առասպելների գրաֆիկական էջերի շարքը Մովսես Խորենացու «Հայոց պատմությում» գրքի առասպելների նկարազարդումներն են։ Բացի նկարազարդումներից Տիգրան Թոքմաւյանը այս թեմային պարբերաբար անդրադարձել է թե՛ գեղանկարներումում, թե՛որմնանկարներում և թե լինոփորագրության մեջ։',
                    painting: 'ԲՆԱՆԿԱՐ',
                    frescas: 'ՈՐՄՆՆԿԱՐ',
                    fresca_sketches: 'ՈՐՄՆՆԿԱՐՆԵՐԻ  ԷՍՔԻԶՆԵՐ',
                    figure_history: 'ՖԻԳՈՒՐԱՏԻՎ ՊԱՏՄԱԿԱՆ',
                    figure_household: 'ՖԻԳՈՒՐԱՏԻՎ ԿԵՆՑԱՂԱՅԻՆ',
                    linocut: 'ԼԻՆՈՓՈՐԱԳՐՈՒԹՅՈՒՆ',
                    illustrations: 'ՆԿԱՐԱԶԱՐԴՈՒՄՆԵՐ',
                    other: 'ԱՅԼ',
                    temp_home_description: `
                    <p>Տիգրան Թոքմաջյանի (1923-2004) բնանկարները, պատմական և կենցաղային կտավները, մեծածավալ բազմաֆիգուր որմնանկարները կարևոր տեղ են զբաղեցնում 20-րդ դարի հայ կերպարվեստում։</p>
                    <p>Նկարչի կենսահաստատ ստեղծագործությունների կենտրոնում Հայաստանն է և հայի ինքնությունը կերտող բաղադրիչները։ Ըստ նրա դրանք են ՝ հայ ազգի պատմության կարևվոր, ճակատագրագան իրադարձությունները, մշակույթի կարևորագույն գործիչները՝ իրենց թողած ժառանգությամբ և բնությունը՝ այն միջավայրը, ուր ապրում և արարում է հայ մարդը։</p>
                    <p>Տիգրան Թոքմաջյանի ստեղծագործություններից կան ՀՀ Ազգային պատկերասրահում, Մայր Աթոռ Սբ․ Էջմիածնի Վեհարանում, Հայաստանի Նկարիչների Միության պահոցում, պետական և մասնավոր այլ հավաքածուներում Հայաստանում, ԱՄՆ֊-ում, Բելգիայում, Մեծ Բրիտանիայում և այլուր:</p>
                    <p>Ավելին շուտով․․․</p>
                    `
                }
            }
        },
        fallbackLng: "am",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
