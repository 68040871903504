import { Spinner } from 'react-bootstrap';

import styles from './loader.module.scss';

interface ILoaderProps {
    style?: React.CSSProperties;
}

export const Loader: React.FC<ILoaderProps> = ({style = {}}): JSX.Element => {
    return (
        <div className={styles.loader} style={style} id='loader'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};
